import { Transition } from "@headlessui/react";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface IFeedbackProviderProps {}

export interface IFeedbackContext {
  enqueueFeedback: (
    message: string,
    isError?: boolean,
    hideXIcon?: boolean,
  ) => void;
}

export const FeedbackContext = createContext<IFeedbackContext>(
  {} as IFeedbackContext,
);
export const useFeedback = (): IFeedbackContext => useContext(FeedbackContext);

const FeedbackProvider: React.FunctionComponent<IFeedbackProviderProps> = ({
  children,
}) => {
  const [message, setMessage] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const enqueueFeedback = useCallback(
    (message: string, isError: boolean = false) => {
      setMessage(message);
      setIsError(isError);
      setShow(true);
    },
    [],
  );

  useEffect(() => {
    if (show === true) {
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [show]);

  return (
    <FeedbackContext.Provider
      value={useMemo(() => ({ enqueueFeedback }), [enqueueFeedback])}
    >
      <div className="fixed right-0 top-0 z-20 flex items-start justify-end px-4 py-6 sm:p-6">
        <Transition
          show={show}
          enter="ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`rounded-md ${
              isError ? "bg-red-50" : "bg-green-50"
            }  p-4`}
          >
            <div className="flex">
              <div className="flex-shrink-0">
                {isError ? (
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                ) : (
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="ml-3">
                <p
                  className={`text-sm font-medium
                ${isError ? "text-red-800" : "text-green-800"}`}
                >
                  {message}
                </p>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    className={`inline-flex
                    ${
                      isError
                        ? "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50"
                        : "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50"
                    }
                     rounded-md p-1.5   focus:outline-none focus:ring-2 focus:ring-offset-2 `}
                    onClick={() => setShow(false)}
                  >
                    <span className="sr-only">Dismiss</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
