import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";

let apolloClient: ApolloClient<NormalizedCacheObject>;

export function getApolloClient(ctx?: any) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: createUploadLink({
      uri: process.env.GRAPHQL_URL,
      credentials: "include",
      headers: {
        Cookie: ctx?.req ? ctx.req.headers.cookie : undefined,
      },
      useGETForQueries: false,
    }),
    cache: new InMemoryCache({
      dataIdFromObject: (object) =>
        object.id ? `${object.__typename}${object.id}` : object.__typename,
      typePolicies: {
        NewsPublisher: {
          fields: {
            news: {
              // https://www.apollographql.com/docs/react/pagination/key-args/
              keyArgs: false,
              merge(existing = [], incoming: any[]) {
                return [...existing, ...incoming];
              },
            },
          },
        },
        News: {
          fields: {
            comments: {
              // https://www.apollographql.com/docs/react/pagination/key-args/
              keyArgs: false,
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
          },
        },
        Category: {
          fields: {
            comments: {
              // https://www.apollographql.com/docs/react/pagination/key-args/
              keyArgs: false,
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
          },
        },
        User: {
          fields: {
            comments: {
              // https://www.apollographql.com/docs/react/pagination/key-args/
              keyArgs: false,
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
          },
        },
        Query: {
          fields: {
            comments: {
              keyArgs: (args) => {
                if (args) {
                  if (args.content) {
                    return ["type", "content"];
                  } else if (args.type) {
                    return ["type"];
                  } else {
                    return Object.keys(args);
                  }
                }
              },
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
            reports: {
              keyArgs: (args) => {
                if (args.type) {
                  return ["type"];
                } else {
                  return Object.keys(args);
                }
              },
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
            publishers: {
              keyArgs: (args) => {
                if (args) {
                  if (args.search) {
                    return ["search"];
                  } else if (args.languageCode || args.type) {
                    return ["languageCode", "type"];
                  }
                }
                return [];
              },
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
            categories: {
              keyArgs: (args) => {
                if (args) {
                  if (args.name || args.explore) {
                    return ["name", "explore"];
                  }
                }
                return [];
              },
              merge(existing = [], incoming: any[]) {
                const uniqueIncoming = incoming.filter(
                  (newItem) =>
                    !existing.find((item) => item.__ref === newItem.__ref)
                );

                return [...existing, ...uniqueIncoming];
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState: any = null) {
  const _apolloClient = apolloClient ?? getApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
